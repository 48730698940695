import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Transformer } from '@parashift/shared/models';
import { TransformerType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => Transformer,
  endpointUrl: TransformerType
})
export class TransformerService extends BaseApiService<Transformer> {
  className = TransformerService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }
}
